.fetch-player {
	margin: 0.5em;
}

.suggestion-line {
	display: flex;
	align-items: center;
	color:#444;
	font-size: 95%;
}

.suggestion-logo {
	width: 3em;
	height: 1.6em;
	margin-right: 1em;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}

.suggestion-logo img {
	max-height: 5em;
	position:relative;
	top:18px;
}

.suggestion-year {
	margin-left: auto;
	margin-right: 0.6em;
	color: #FFF;
	background: #555;
	padding:0.3em 1em;
	border-radius:3px;
	font-size:85%;
	font-weight: 800;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 14em;
  padding: 0.25em 0.5em;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0.3em;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

