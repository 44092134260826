.topbar {
	display: flex;
	background: linear-gradient(45deg, #444444, #AAAAAA);
	padding: 5px 0;
}

.board {
	position: relative;
	width: 100%;
}

.field {
	background-color: #7b9c60;
	background-image:url('./positions2.png');
    background-size: 60em;
    background-position: center;
	background-repeat: no-repeat;

	width: 100%;
	height: 31em;
}

.interchange {
	background-color: #CCC;
	box-shadow: 0 0 1em inset #444;
	height: 12em;
}

.positions, .slots {
    display: grid;
    grid-template-columns: repeat(5, minmax(8em, 12em));
	grid-template-rows: repeat(8, 1fr);
    justify-content: center;
    align-items: center;

	position: absolute;
	width: 100%;
	top: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.position, .position-name, .empty-position, .unfilled-position {
	height: 4em;
	margin: 0.6em;
	border-radius: 3px;
	color: white;
	text-align: center;
	position: relative;
}

.slots {
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
}

.empty-position {
	background-color: #00000080;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	text-shadow: 0px 0px 2px #000;
}

.empty-position.position-I10 {
	background-color: red;
}

.empty-position.position-row-F:not(.gutter):before {
	content: "FORWARD";
}

.empty-position.position-row-HF:not(.gutter):before {
	content: "HALF-FORWARD";
}

.empty-position.position-row-C:before, .empty-position.position-row-R:before {
	content: "MID";
}

.empty-position.position-row-HB:not(.gutter):before {
	content: "HALF-BACK";
}

.empty-position.position-row-B:not(.gutter):before {
	content: "BACK";
}

.empty-position.position-R3:before {
	content: "RUCK";
}

.empty-position.position-B3:not(.gutter):before {
	content: "FULL-BACK";
}

.empty-position.position-F3:not(.gutter):before {
	content: "FULL-FORWARD";
}

.trash {
	font-size:200%;
}

.slots-dragging {
	opacity: 0.2;
}

.position {
    box-shadow: 0 1px 0.2em rgba(0,0,0,0.6), 0 1px 0.6em rgba(0,0,0,0.2);

	cursor: move;

	white-space: nowrap;
	overflow: hidden;
	transition: box-shadow ease-out 0.15s, opacity ease-out 0.25s;
}

.position-dragging {
	box-shadow: 0.25em 0.25em 0.5em rgba(0,0,0,0.75);
}

.position-row-R:not(.position-dragging), .position-row-C:not(.position-dragging) {
	left: -50%;
}

.position-C2:not(.position-dragging), .position-C5 {
	top: -50%;
}

.position-row-I {
	/* top: 12%;*/
}

.position.position-row-I {
	filter: saturate(0.2);
}

.position.position-row-I .card-team {
	filter: contrast(0.6);
}

.position-R1, .position-C1, .position-R2, .position-R5 {
    opacity: 0;
}

.position.position-I10:not(.position-dragging) {
	opacity: 0;
}

.card-name {
	font-weight: bold;
}

.card-year {
    font-size: 60%;
    background: rgba(255, 255, 255, 0.3);
    margin: 3px;
}

.card-pavline {
    display: flex;
    justify-content: space-evenly;
}

.card-pavline-category-top {
    font-size: 50%;
    text-transform: uppercase;
    opacity: 0.6;
}

.card-pavline-category-bottom {
    font-size: 75%;
	opacity: 0.8;
}

.card .pavchart {
    width: 1.8em;
    position: absolute;
    top: -0.5em;
    right: 0;
	filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.25));
}

.card-team {
	width: 1.9em;
    height: 1.8em;
    margin-right: 1em;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
	z-index: 3;
    top: 0.1em;
    left: 0.2em;
    filter: saturate(0.5);
    border-radius: 50%;
    box-shadow: 1px 1px 5px black;
}

.card-team img {
	max-height: 5em;
	position:relative;
	top:18px;
}

.team-analysis {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	box-shadow: 0 0 3px inset #666;
}

.analysis.analysis-Team {
    background: linear-gradient(0deg, #7b9c60, #7b9c6080);
}

.analysis.analysis-Forwards {
    background: linear-gradient(0deg, #85616c, #85616c60);
	background: linear-gradient(0deg, hsl(283, 34%, 25%), hsla(283, 34%, 76%, 0.50));
}

.analysis.analysis-Mids {
    background: linear-gradient(0deg, #666A86, #666A8690);
	background: linear-gradient(0deg, hsl(169, 52%, 25%), hsla(169, 52%, 65%, 0.50));
}

.analysis.analysis-Backs {
    background: linear-gradient(0deg, #59715A, #59715A90);
	background: linear-gradient(0deg, hsl(45, 79%, 25%), hsla(45, 79%, 65%, 0.50));
}

.analysis {
	text-align: center;
	position: relative;
}

.analysis .pavchart {
	display: block;
	margin: auto;
    width: 44%;
	height: 2em;
	filter: drop-shadow(2px 3px 2px rgba(0,0,0,0.75));
}

.analysis .card-pavline {
	margin: 0 29%;
}

.analysis .card-pavline-category-bottom {
	background-color: rgba(0, 0, 0, 0.35);
    color: white;
    padding: 0 5px;
	border-radius: 1px;
}

.analysis-title {
    color: #333;
	font-size: 70%;
    background-color: rgba(0, 0, 0, 0.35);
    color: white;
    display: inline-block;
    width: 40%;
    margin-top: 2px;
    border-radius: 1px;
}

.analysis-title-value {
    font-weight: bold;
    margin-left: 0.6em;
}


/*
 * This stuff is for player careers
 */

.current-player {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    width: 100%;
}

.player-name {
	min-width: 10em;
	font-weight: 200;
    color: white;
    text-shadow: 2px 2px 3px black;
	grid-row: 1;
	grid-column: 2;
}

.current-player-chart {
    height: 3em;
    flex-shrink: 0;
    margin: auto;
	grid-row: 1;
	grid-column: 1;
}

.current-player-chart .chart-text {
	fill: #EEE;
	font-size:120%;
}

.line-graph {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 0.4s ease-out forwards;
}

.fade-in {
	opacity: 0;  /* make things invisible upon start */
	animation: fadeIn ease-in 0.2s;
	animation-fill-mode: forwards;
}

@keyframes dash {
  {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

footer {
	text-align: center;
	font-size: 70%;
	color: #888;
	margin:1em;
}


@media only screen and (max-width: 900px) {
	.board {
		font-size:12px;
	}

	.positions, .slots {
		grid-template-columns: repeat(5, minmax(4em, 12em));
	}

	.card-name {
		font-size: 90%;
		font-weight: 400;
		position: relative;
		z-index: 5;
	}

	.card-team {
		top: 0.8em;
		width: 1.5em;
		height: 1.5em;
		left: 0.1em;
	}

	.card .pavchart {
		top: 0;
	}

	.card-year {
		margin-left: 0;
		margin-right: 0;
	}

	.topbar {
		flex-wrap: wrap;
	}

	.current-player .player-name {
		min-width: auto;
	}

	.current-player-chart {
		max-width: 70vw;
	}
}
